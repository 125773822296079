

import useVuelidate from '@vuelidate/core';
import { defineComponent,ref,onUpdated } from 'vue'
import { useStore } from 'vuex';
import InfoAdicionalValidacion from '../../validation/infoAdicional';

export default defineComponent({
    props: {
      scopeAdicional: String,
       parametricas: {}
    },
    setup(props) {

      const mostrarMensajeLAFT = ref(false);
      const store = useStore();

      const documentosIdentidad = ref([]);
      const certificados = ref([]);
      const RUTs = ref([]);
      const estadosFinancieros = ref([]);

      let [objetoArchivos, reglasValidacion] = new InfoAdicionalValidacion().ObtenerReglasValidacion();
      const validacionArchivos = useVuelidate(reglasValidacion, objetoArchivos, {
        $scope: props.scopeAdicional
      })

      const Download = (nombreArchivo: string) => {
        const url =
          process.env.VUE_APP_Gateway_API + "DebidaDiligencia/DescargarArchivoBlobStorage?nombre="+nombreArchivo;
        window.open(url, "_blank");
      };

      onUpdated(() => {
        window.scrollTo(0, 0);
      });

      return {
        documentosIdentidad, certificados,RUTs, estadosFinancieros,
        validacionArchivos, objetoArchivos,
        mostrarMensajeLAFT,
        store, Download
      }
    },
    methods: {
      EliminarDocumentoIdentidad(file: any) {

        this.objetoArchivos.TamañoArchivo -= Number(file.size);

        this.objetoArchivos.DocumentosIdentidad.ExtensionesArchivos
          .splice(this.objetoArchivos.DocumentosIdentidad.ExtensionesArchivos
            .findIndex(x => x.Id == file.uid), 1);

        this.objetoArchivos.NombresArchivos
          .splice(this.objetoArchivos.NombresArchivos
            .findIndex(x => x.Id == file.uid), 1);

        this.objetoArchivos.Archivos.delete(file.uid);
        
        this.ReasignarIndiceNombreArchivos(
          this.ObtenerArchivosPorCategoria('DocumentoIdentidad'), 'DocumentoIdentidad');
      },

      EliminarCertificado(file: any) {

        this.objetoArchivos.TamañoArchivo -= Number(file.size);

        this.objetoArchivos.Certificados.ExtensionesArchivos
          .splice(this.objetoArchivos.Certificados.ExtensionesArchivos
            .findIndex(x => x.Id == file.uid), 1);

        this.objetoArchivos.NombresArchivos
          .splice(this.objetoArchivos.NombresArchivos
            .findIndex(x => x.Id == file.uid), 1);

        this.objetoArchivos.Archivos.delete(file.uid);
       
        this.ReasignarIndiceNombreArchivos(
          this.ObtenerArchivosPorCategoria('CertificadoExistencia'), 'CertificadoExistencia');
      },

      EliminarRUT(file: any) {

        this.objetoArchivos.TamañoArchivo -= Number(file.size);

        this.objetoArchivos.RUT.ExtensionesArchivos
          .splice(this.objetoArchivos.RUT.ExtensionesArchivos
            .findIndex(x => x.Id == file.uid), 1);

        this.objetoArchivos.NombresArchivos
          .splice(this.objetoArchivos.NombresArchivos
            .findIndex(x => x.Id == file.uid), 1);

        this.objetoArchivos.Archivos.delete(file.uid);
        
        this.ReasignarIndiceNombreArchivos(
          this.ObtenerArchivosPorCategoria('RUT'), 'RUT');
      },

      EliminarEstadoFinanciero(file: any) {

        this.objetoArchivos.TamañoArchivo -= Number(file.size);

        this.objetoArchivos.EstadosFinancieros.ExtensionesArchivos
          .splice(this.objetoArchivos.EstadosFinancieros.ExtensionesArchivos
            .findIndex((x: { Id: any; }) => x.Id == file.uid), 1);

        this.objetoArchivos.NombresArchivos
          .splice(this.objetoArchivos.NombresArchivos
            .findIndex(x => x.Id == file.uid), 1);

        this.objetoArchivos.Archivos.delete(file.uid);
        
        this.ReasignarIndiceNombreArchivos(
          this.ObtenerArchivosPorCategoria('EstadoFinanciero'), 'EstadoFinanciero');
      },

      EliminarFirma(file: any) {

        this.objetoArchivos.Firma.Nombre = '';
        this.objetoArchivos.TamañoArchivo -= Number(file.size);

        this.objetoArchivos.Firma.ExtensionesArchivos
          .splice(this.objetoArchivos.Firma.ExtensionesArchivos
            .findIndex(x => x.Id == file.uid), 1);

        this.objetoArchivos.NombresArchivos
          .splice(this.objetoArchivos.NombresArchivos
            .findIndex(x => x.Id == file.uid), 1);

        this.objetoArchivos.Archivos.delete(file.uid);
      },

      CargarDocumentoIdentidad(file: any, fileList: any) {

        this.objetoArchivos.TamañoArchivo += Number(file.size);

        this.objetoArchivos.DocumentosIdentidad.ExtensionesArchivos.push({
          Extension: file.raw.type,
          Id: file.uid
        });

        this.objetoArchivos.NombresArchivos.push({
          Id: file.uid,
          Nombre: file.name 
        });

        this.objetoArchivos.Archivos.append(file.uid, file.raw, 
          this.ObtenerNombreArchivo(file, fileList, 'DocumentoIdentidad'));
      },
      CargarCertificado(file: any, fileList: any) {

        this.objetoArchivos.TamañoArchivo += Number(file.size);

        this.objetoArchivos.Certificados.ExtensionesArchivos.push({
          Extension: file.raw.type,
          Id: file.uid
        });

        this.objetoArchivos.NombresArchivos.push({
          Id: file.uid,
          Nombre: file.name
        });

        this.objetoArchivos.Archivos.append(file.uid, file.raw, 
          this.ObtenerNombreArchivo(file, fileList, 'CertificadoExistencia'));
      },

      CargarRUT(file: any, fileList: any) {

        this.objetoArchivos.TamañoArchivo += Number(file.size);

        this.objetoArchivos.RUT.ExtensionesArchivos.push({
          Extension: file.raw.type,
          Id: file.uid
        });

        this.objetoArchivos.NombresArchivos.push({
          Id: file.uid,
          Nombre: file.name
        })

        this.objetoArchivos.Archivos.append(file.uid, file.raw, 
          this.ObtenerNombreArchivo(file, fileList, 'RUT'));
      },

      CargarEstadoFinanciero(file: any, fileList: any) {
        
        this.objetoArchivos.TamañoArchivo += Number(file.size);

        this.objetoArchivos.EstadosFinancieros.ExtensionesArchivos.push({
          Extension: file.raw.type,
          Id: file.uid
        });

        this.objetoArchivos.NombresArchivos.push({
          Id: file.uid,
          Nombre: file.name
        })

        this.objetoArchivos.Archivos.append(file.uid, file.raw, 
          this.ObtenerNombreArchivo(file, fileList, 'EstadoFinanciero'));
      },

      CargarFirma(file: any) {

        this.objetoArchivos.Firma.Nombre = file.name;
        this.objetoArchivos.TamañoArchivo += Number(file.size);
        this.objetoArchivos.Archivos.append(file.uid, file.raw, file.Name);

        this.objetoArchivos.Firma.ExtensionesArchivos.push({
          Extension: file.raw.type,
          Id: file.uid
        });

        this.objetoArchivos.NombresArchivos.push({
          Id: file.uid,
          Nombre: file.name
        })
      },

      ObtenerNombreArchivo(file: any, fileList: any, categoriaArchivo: string): string{
        //Obtiene el índice que ocupa el archivo en el arreglo fileList.
        const indexFile: number = fileList.map((archivo: { uid: any; }) => {
          return archivo.uid
        }).indexOf(file.uid, 0) + 1;
        
        //Nuevo nombre del archivo.
        const nombreArchivo: string = 
        categoriaArchivo +'_'+ 
        localStorage.getItem('TipoIdenticacionDD') +'_'+
        localStorage.getItem('NumeroIdentificacionDD') +'_'+
        indexFile +'.'+
        file.name.split('.').pop();

        return nombreArchivo;
      },

      ReasignarIndiceNombreArchivos(archivosPorCategoria: ArchivoCategoria[], categoriaArchivo: string)
      {

        archivosPorCategoria.forEach((archivo, indexFile) => {
          
           //Nuevo nombre del archivo para reasignar el índice.
          const nombreArchivo: string = 
          categoriaArchivo +'_'+
          localStorage.getItem('TipoIdenticacionDD') +'_'+
          localStorage.getItem('NumeroIdentificacionDD') +'_'+
          `${indexFile + 1}.` +
          archivo.Archivo.name.split('.').pop();

          //Actualiza el archivo renombrado en "objetoArchivos.Archivos".
          this.objetoArchivos.Archivos.set(archivo.Id, archivo.Archivo, nombreArchivo);
        })
      },

      ObtenerArchivosPorCategoria(categoriaArchivo: string): ArchivoCategoria[]{
        
        const archivos: ArchivoCategoria[] = [];
        
        Array.from(this.objetoArchivos.Archivos.entries()).map((archivo) => {
          
          if(archivo[1] instanceof File)
          {
            if(archivo[1].name.includes(categoriaArchivo))
            {
              const archivoCategoria: ArchivoCategoria = 
              { 
                Id : archivo[0], 
                Archivo: archivo[1]
              }

              archivos.push(archivoCategoria);
            }
          }
        });

        return archivos;
      }
    }
  })
